<template>
    <div class="login">
        <img src="@/assets/image/logo.png" alt="" class="logo">
        <p class="title">通辽远程接访管理分析平台</p>
        <div class="box">
            <form action="" :model="form" ref="form">
                <div class="item">
                <i class="ico ico1"></i>
                <input type="text" placeholder="请输入用户名" v-model="form.username" @keyup.enter="handleSubmit" ref="username">
                </div>
                <div class="item">
                <i class="ico ico2"></i>
                <input :type="inputType" placeholder="请输入密码" v-model="form.password" @keyup.enter="handleSubmit" ref="password">
                <i class="ico ico4" v-if="isShowPassword" @click="showPassword"></i>
                <i class="ico ico4_act" v-else @click="showPassword"></i>
                </div>
                <div class="item">
                <Slider ref="slider" class="slider"/>
                </div>
                <button class="btn" @click.prevent="handleSubmit">登录</button>
            </form>
        </div>
        <p class="copyright">朗正集成信息科技（南京）有限公司 技术支持</p>
    </div>
</template>

<script>
import{login} from '@/utils/api'
import Slider from '@/components/Slider.vue'
export default {
    name: 'Login',
    components: {
        Slider
    },
    data() {
    return {
      isShowPassword: true, //判断显示密码
      inputType: 'password',  //密码类型
      form: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    // 切换密码显示隐藏
    showPassword() {
      this.isShowPassword = !this.isShowPassword
      this.inputType = this.inputType==='password' ? 'text' : 'password'
    },
    // 登录
    // handleSubmit(){
    //     this.$router.push('/index');
    // },
    handleSubmit() {
      if(this.$refs.slider.confirmSucc===true){
        if(this.form.username!=='' || this.form.password!==''){
          // var form = convertObj(this.form)
          // console.log(form);
          this.$store.dispatch("request_login",this.form).then((res)=>{
            // console.log(res);
              if(res.data.code == 200){
                localStorage.setItem('token', res.data.data.token)
                //根据用户权限, 获取动态过滤后的路由表
                //this.$store.getters.get_routes

                //将动态过滤后的路由表添加到router已有的路由表中
                // this.$router.addRoutes( this.$store.getters.get_routes );
                
                //可以通过 this.$router.getRoutes() 获取最新的路由表

                this.$router.push('/');
              }
            })
          // login({
          //   username:this.form.username,
          //   password:this.form.password,
          // }).then(res =>{
          //   console.log(res);
          // })
          // var form = convertObj(this.form)
          // console.log(form);
          // this.$router.push({ path: '/index' })
          // login(form).then(res => {
          //   if(res.data.code===0){
          //     localStorage.setItem('token', res.data.data.access_token)
          //     localStorage.setItem('nickname', res.data.data.nickname)
          //     localStorage.setItem('menu', res.data.menu)
          //     localStorage.setItem('systemMenu', res.data.sys)
          //     localStorage.setItem('systembj', res.data.sys1)
          //     localStorage.setItem('unit', res.data.data.abbreviation)
          //     localStorage.setItem('isWhole', res.data.is_whole)
          //     if(res.data.menu[0] == 1) {
          //       this.$router.push({
          //       path: '/indicator'
          //     })
          //     }else if (res.data.menu[0] == 0 && res.data.menu[1] == 1) {
          //       this.$router.push({
          //       path: '/prosecutorCondition'
          //     })
          //     }else if (res.data.menu[0] == 0 && res.data.menu[1] == 0 && res.data.menu[2] == 1) {
          //       this.$router.push({
          //       path: '/discussion'
          //     })
          //     }else if (res.data.menu[0] == 0 && res.data.menu[1] == 0 && res.data.menu[2] == 0 && res.data.menu[3] == 1) {
          //       this.$router.push({
          //       path: '/administrative'
          //     })
          //     }else if (res.data.menu[0] == 0 && res.data.menu[1] == 0 && res.data.menu[2] == 0 && res.data.menu[3] == 0 && res.data.menu[4] == 1) {
          //       this.$router.push({
          //       path: '/organization'
          //     })
          //     }else if (res.data.menu[0] == 0 && res.data.menu[1] == 0 && res.data.menu[2] == 0 && res.data.menu[3] == 0 && res.data.menu[4] == 0 && res.data.menu[5] == 1) {
          //       this.$router.push({
          //       path: '/role'
          //     })
          //     }
          //   }else{
          //     this.$message.error(res.data.msg)
          //   }
          // })
        }
      }else{
        this.$message.error('请进行滑块验证！')
      }
    }
  }

}
</script>

<style lang="less" scoped>
input:-webkit-autofill {
    box-shadow:0 0 0 1000px white inset !important;
}
.login{
  width: 100%;
  min-height: 100vh;
//   padding: 60px 0;
//   color: #fff;
  text-align: center;
  background: url(~@/assets/image/bg.png) no-repeat center center;
  background-size: 100% 100%;
  .logo{
    width: 150px;
    height: 150px;
    // margin: 56px auto 52px;
    margin: 7% auto 3%;
  }
  .title{
    text-align: center;
    font-size: 54px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 75px;
  }
  .copyright{
    font-size: 18px;
    margin-top: 112px;
    color: #fff;
  }
  .box{
    width: 583px;
    height: 380px;
    background: rgba(0,66,255,0.4100);
    border-radius: 2px;
    border: 1px solid #0073FF;;
    position: relative;
    left: 50%;
    margin-left: -292px;
    margin-top: 51px;
    padding: 12px 40px;
    .item{
      position: relative;
      width: 100%;
      height: 59px;
      margin-top: 24px;
      border-radius: 2px;
      background: #fff;
      input{
        position: relative;
        width: 75%;
        height: 100%;
        font-size: 18px;
        line-height: 59px;
        border: none;
        outline: none;
      }
      .ico{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
      }
      .ico1, .ico2{
        left: 17px;
      }
      .ico1{
        width: 30px;
        height: 30px;
        background-image: url(~@/assets/image/ic_login_user.png);
      }
      .ico2{
        width: 30px;
        height: 30px;
        background-image: url(~@/assets/image/ic_login_password.png);
      }
      .ico4, .ico4_act{
        width: 20px;
        height: 20px;
        right: 18px;
        cursor: pointer;
      }
      .ico4{
        background-image: url(~@/assets/image/icon4.png);
      }
      .ico4_act{
        background-image: url(~@/assets/image/icon4_act.png);
      }
    }
    .btn{
      border: none;
      display: block;
      width: 503px;
      height: 59px;
      margin: 35px auto;
      font-size: 24px;
      color: #fff;
      letter-spacing: 20px;
      line-height: 60px;
      border-radius: 2px;
      background: #1890FF;
      cursor: pointer;
    }
  }
  .bg{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>