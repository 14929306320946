<template>
  <div class="slider" ref="slider">
    <div class="bg"></div>
    <div class="tip">{{tip}}</div>
    <div class="btn" ref="btn" @mousedown="handleDrag($event)">
      <i class="icon ico3"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slider',
  data() {
    return {
      tip: '向右拖动滑块完成验证',  //滑块上的提示
      moveState: false, //拖动状态
      beginClientX: 0,  //距离滑块左端的距离
      maxWidth: 411,  //拖动的最大宽度
      confirmSucc: false, //验证判断
    }
  },
  methods: {
    handleDrag(e) {
      if(!this.confirmSucc) {
        e.preventDefault && e.preventDefault()  //阻止文字选中等
        this.moveState = true
        this.beginClientX = e.clientX
      }
    },
    succFunction() {
      this.confirmSucc = true
      this.tip = '验证通过'
      if(window.addEventListener){
        document.getElementsByTagName('html')[0].removeEventListener('mousemove', this.handleMousemove)
        document.getElementsByTagName('html')[0].removeEventListener('mouseup', this.handleMouseup)
      }else{
        document.getElementsByTagName('html')[0].removeEventListener('mouseup', () => {})
      }
      document.getElementsByClassName('tip')[0].style.color = 'rgba(0,0,0,0.2500)'
      document.getElementsByClassName('btn')[0].style.left = this.maxWidth + 'px'
      document.getElementsByClassName('bg')[0].style.width = this.maxWidth + 'px'
    },
    handleMousemove(e) {
      if(this.moveState){
        var width = e.clientX - this.beginClientX
        if(width>0 && width<=this.maxWidth){
          document.getElementsByClassName('btn')[0].style.left = width + 'px'
          document.getElementsByClassName('bg')[0].style.width = width + 'px'
        }else if(width>this.maxWidth){
          this.succFunction()
        }
      }
    },
    handleMouseup(e) {
      this.moveState = false
      var width = e.clientX - this.beginClientX
      if(width<this.maxWidth){
        document.getElementsByClassName('btn')[0].style.left = 0 + 'px'
          document.getElementsByClassName('bg')[0].style.width = 0 + 'px'
      }
    }
  },
  mounted() {
    document.getElementsByTagName('html')[0].addEventListener('mousemove', this.handleMousemove)
    document.getElementsByTagName('html')[0].addEventListener('mouseup', this.handleMouseup)
  }
}
</script>

<style lang="less" scoped>
.slider{
  position: relative;
  display: inline-block;
  width: 100%;
  height: 59px;
  user-select: none;

  .bg{
    width: 0;
    height: 100%;
    // background-color: #53afdc;
    // border-radius: 10px;
  }
  .tip{
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: 400;
    color: rgba(0,0,0,0.2500);
    line-height: 24px;
  }
  .btn{
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 59px;
    text-align: center;
    background: #1890FF;
    border-radius: 2px;
    cursor: move;
    .ico3{
      width: 36px;
      height: 36px;
      background-image: url(~@/assets/image/ic_login_slider.png);
      background-size: 100% 100%;
    }
  }
}
</style>